<template>
  <div class="card card-custom card-border cursor-pointer" @click="$emit('click', {task: task})"
       style="background-color: #f3f6f9; border:1px solid #0b5a85; border-radius: 12px;">
    <div class="card-header">
      <div class="card-title">
        <h2 class="card-label">{{ task.sample && task.sample && task.sample.collection_number || "-" }}</h2>
      </div>
      <div class="card-toolbar">
        <span href="#" class="btn btn-icon btn-danger btn-xs p-0 rounded-full" style="border-radius: 50% !important;">1
        </span>
      </div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('model_house.exit_date_from_depo') }} </b>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ task.process_date | momentDateFormat }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('model_house.entry_date_to_model_house') }} </b>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ task.process_date | momentDateFormat }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('model_house.sample_responsible_person') }}</b>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ Number(task.modeler_user_id) ? task.modeler_user.name : task.planter_user }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40"><b>{{ $t('model_house.client_name') }}</b>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ task.sample.company.name }}</p>
      </div>
      <div class="d-flex justify-content-between">
        <p class="flex-40">
          <b>{{ $t('model_house.status') }}</b>
        <p class="text-right px-2 font-weight-bolder">:</p>
        <p class="pl-3 flex-60">{{ task.status.translations[0].name }}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TaskCard",
  props: {
    task: {
      required: true,
    }
  }
}
</script>

<style scoped type="scss">
.flex-40 {
  flex: 40% !important;;
}

.flex-60 {
  flex: 60% !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.card-header {
  padding: 2rem 2.25rem 0 2.25rem;
  background-color: #ffffff;
  border-bottom: 0px;
}

.card.card-custom > .card-body {
  padding: 0rem 2.25rem 2rem 2.25rem;
}

/* .card-custom {
  border-radius: 20px !important;
  border: 3px solid $ primary-gray !important;
  box-shadow: 0.5px 1px $ primary-gray-shadow !important;
} */


</style>